export default {
    data(){
        return {
            dd_redirect_codes: [
                { label: 'Permanent', value: 301 },
                { label: 'Temporary', value: 302 },
            ],
        };
    },
    methods: {
        RedirectCodesLabel(k){
            let name = '-';
            this.dd_redirect_codes.forEach(s => {
                if (s.value === k) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}
