<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Redirects</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='from_url' header='Da' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            <a :href='getFrontendUrl(slotProps.data.from_url)' target='_blank'>{{ slotProps.data.from_url }}</a>
                        </template>
                    </Column>

                    <Column field='to_url' header='A' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            <a :href='getFrontendUrl(slotProps.data.to_url)' target='_blank'>{{ slotProps.data.to_url }}</a>
                        </template>
                    </Column>

                    <Column field='code' header='Tipo' :sortable='true' style='min-width:2rem'>
                        <template #body='slotProps'>
                            ({{slotProps.data['code']}}) {{RedirectCodesLabel(slotProps.data['code'])}}
                        </template>
                    </Column>

                    <Column field='active' class='text-center' header='Attivo' :sortable='true' style='min-width:2rem'>
                        <template #body='slotProps'>
                            {{slotProps.data['active'] ? "Yes" : "No"}}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='text-purple-300 hover:text-purple-400 pointer ml-2'
                            title='Apri in nuovo Tab'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-blue-400 hover:text-blue-600 pointer ml-2'
                            title='Apri nello stesso Tab'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        /></template>
                        <template #body='slotProps'>
                            <Button class='mr-2 mb-2 p-button-danger' v-if='!slotProps.data.active' @click='setStatus(slotProps.data, true)'>
                                <font-awesome-icon
                                    title='Attiva'
                                    :style='{color: "white", fontSize:"1.41em"}'
                                    :icon="['fas', 'traffic-light']"
                                />
                            </Button>
                            <Button @click='setStatus(slotProps.data, false)' class='mr-2 mb-2 p-button-success' v-else>
                                <font-awesome-icon
                                    title='Disattiva'
                                    :style='{color: "white", fontSize:"1.41em"}'
                                    :icon="['fas', 'traffic-light']"
                                />
                            </Button>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                            <Button icon='pi pi-trash' class='mr-2 mb-2 p-button-danger' title='Elimina'
                                    @click='remove(slotProps.data.id)'></Button>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Web/WebRedirects');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import DdCodes from '../../../mixins/dd_redirect_codes';

import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'delete', 'savePlain']),
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        async setStatus(data, active){
            data.active = active;
            await this.savePlain(data);
            this.refreshList();
            this.ShowWarning('Attenzione', "La modifica sarà live al prossimo deploy web. Richiederlo se urgente");
        },
        async remove(data){
            this.$confirm.require({
                message: 'Sei sicuro di voler eliminare questo redirect? Comunque avrà effetto solo dopo il prossimo deploy.',
                header: 'Operazione non annullabile',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.delete(data).then(() => {
                        this.ShowSuccess('Ottimo', 'Record cancellato con successo');
                        this.ShowWarning('Attenzione', "La modifica sarà live al prossimo deploy web. Richiederlo se urgente");
                        this.refreshList();
                    }).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        }
    },
    mixins: [
        Notifications,
        Navigations,
        DdCodes
    ],
};
</script>
